<template>
	<div class="body">
		<!--导航栏--->
		<section class="top">
			<van-nav-bar :title="isReturn ? '售后详情' : '换货详情'" z-index="1000" left-arrow :fixed='isTop'
				@click-left="backToServe" />
		</section>
		<!-- 商家拒绝换货 -->
		<section class="changeProcess" v-if="getOrderRefunds.barterType === '9'">
			<van-steps direction="vertical" active=1 active-color="#E54320" inactive-color="#999999">
				<van-step>
					<p>提交申请<span> {{ getOrderRefunds.createTime || '' }}</span></p>
				</van-step>
				<van-step>
					<p>商家拒绝换货</p>
					<p class="promptText">拒绝理由： {{ getOrderRefunds.refuseRefundReson || '无' }}</p>
				</van-step>
			</van-steps>
		</section>
		<!-- 商家拒绝退货退款 -->
		<section class="changeProcess" v-if="getOrderRefunds.barterType === '11'">
			<van-steps direction="vertical" active=1 active-color="#E54320" inactive-color="#999999">
				<van-step>
					<p>提交申请<span> {{ getOrderRefunds.createTime || '' }}</span></p>
				</van-step>
				<van-step>
					<p>商家拒绝退货退款</p>
					<p class="promptText">拒绝理由： {{ getOrderRefunds.refuseRefundReson || '无' }}</p>
				</van-step>
			</van-steps>
		</section>
		<!-- 换货流程,申请退货 -->
		<section class="changeProcess" v-if="getOrderRefunds.barterType != '9' && !isReturn">
			<p class="remainTime" v-if="activeStatus === 0">等待商家处理<span> {{ closeTime.hours }}小时{{
				closeTime.minutes
			}}分钟{{
	closeTime.second
}}秒后，将自动确认</span></p>
			<p class="remainTime" v-else-if="activeStatus === 1">待退货<span>{{ closeTime.day }}天 {{
				closeTime.hours
			}}小时{{
	closeTime.minutes
}}分钟{{
	closeTime.second
}}秒后，未发货将自动取消</span></p>
			<p class="remainTime" v-else-if="activeStatus === 2">待商家签收</p>
			<p class="remainTime" v-else-if="activeStatus === 3">待商家发货</p>
			<p class="remainTime" v-else>换货已完成</p>
			<van-steps direction="vertical" :active="activeStatus" active-color="#E54320" inactive-color="#999">
				<van-step>
					<p>提交申请<span class="createTimeSize"> {{ getOrderRefunds.createTime }}</span></p>
					<p class="promptText" v-if="getOrderRefunds.barterType === '1'">
						您已成功发起换货申请，请耐心等待商家处理,如果商家拒绝，您可以修改申请后再次发起，商家会重新处理</p>
				</van-step>
				<van-step>
					<div v-if="getOrderRefunds.barterType === '2'">
						<p>商家同意换货</p>
						<span class="promptText">商家同意换货，请将商品在规定时间内退回商家指定地址</span>
					</div>
					<div v-else-if="getOrderRefunds.barterType > '2'">商家同意换货</div>
					<div v-else>商家审核</div>
				</van-step>
				<van-step>
					<p>退回商品</p>
				</van-step>
				<van-step>
					<p>商家已签收</p>
				</van-step>
				<van-step>
					<p>商家发出货物</p>
					<p v-if="getOrderRefunds.barterType === '5'" class="promptText">
						{{ getOrderRefunds.sellerLogisticsDesc ? getOrderRefunds.sellerLogisticsDesc : "" }}：{{
							getOrderRefunds.sellerLogisticsNo
						}}<span class="copy" :dataClipboard="getOrderRefunds.sellerLogisticsNo"
							@click="getCopy('.copy')">复制</span> </p>
				</van-step>
				<van-step>
					<p>换货完成</p>
				</van-step>
			</van-steps>
		</section>
		<!-- 退货退款流程 -->
		<section class="changeProcess" v-if="getOrderRefunds.barterType != '11' && isReturn">
			<p class="remainTime" v-if="activeStatus1 === 0">等待商家处理<span> {{ closeTime.hours }}小时{{
				closeTime.minutes
			}}分钟{{
	closeTime.second
}}秒后，将自动确认</span></p>
			<p class="remainTime" v-else-if="activeStatus1 === 1">商家同意退货<span>请将商品在规定时间内退回商家指定地址</span></p>
			<p class="remainTime" v-else-if="activeStatus1 === 2">退回中</p>
			<p class="remainTime" v-else-if="activeStatus1 === 3">商家已签收</p>
			<p class="remainTime" v-else>售后完成</p>
			<van-steps direction="vertical" :active="activeStatus1" active-color="#E54320" inactive-color="#999">
				<van-step>
					<p>提交申请<span class="createTimeSize"> {{ getOrderRefunds.createTime }}</span></p>
					<p class="promptText" v-if="getOrderRefunds.barterType === '1'">
						您已成功发起换货申请，请耐心等待商家处理,如果商家拒绝，您可以修改申请后再次发起，商家会重新处理</p>
				</van-step>
				<van-step>
					<div v-if="getOrderRefunds.barterType === '2'">
						<p>商家同意退款退货</p>
						<span class="promptText">商家同意换货，请将商品在规定时间内退回商家指定地址</span>
					</div>
					<div v-else-if="getOrderRefunds.barterType > '2'">商家同意退款退货</div>
					<div v-else>商家审核</div>
				</van-step>
				<van-step>
					<p>退回商品</p>
				</van-step>
				<van-step>
					<p>商家已签收</p>
				</van-step>
				<van-step>
					<p>售后完成</p>
				</van-step>
			</van-steps>
		</section>
		<!-- 填写物流信息 -->
		<section class="writeLogisticsInfo" v-if="getOrderRefunds.barterType === '2'">
			<p>退回地址</p>
			<div class="main">
				<!-- <p>{{ JSON.stringify(ceshi) }}</p> -->
				<div class="logisticsInfo">
					<van-image round width="0.6054rem" height="0.6054rem" :src="orderInfo.imgUrl" />
					<div class="shopAddressInfo">
						<div class="copyInfo">
							<p><span>{{ ShopSales.reName }}</span>{{ ShopSales.rePhone }}</p>
							<span class="copy copyShopInfo"
								:dataClipboard="ShopSales.reName + '' + ShopSales.rePhone + '' + ShopSales.province + ShopSales.city + ShopSales.area + ShopSales.address"
								@click="getCopy('.copyShopInfo')">复制</span>
						</div>
						<p class="address">{{
							ShopSales.province + ShopSales.city + ShopSales.area + ShopSales.address
						}}</p>
					</div>
				</div>
				<div class="logisticsBtn">
					<van-button round type="info" plain size="small" color="#333333" @click="goWrite">填写物流信息</van-button>
				</div>
			</div>
		</section>
		<!-- 换货商品详情 -->
		<section class="shopInfo">
			<div class="shopName">
				<van-image round width="0.6rem" height="0.6919rem" :src="orderInfo.imgUrl" alt="图片加载失败" />
				<span>{{ orderInfo.shopName || '--' }}</span>
				<!-- 点击跳转到店铺页面-->
				<van-icon name="arrow" size="0.4rem" @click="toShopDetail(getOrderRefunds.shopId)" />
			</div>
			<div class="goodsInfo">
				<div class="goodsList">
					<div>
						<van-image width="100" height="100" fit="fill" :src="orderInfo.picUrl" alt="图片加载失败" />
					</div>
					<div class='goodsText'>
						<div>
							<p>{{ orderInfo.name || '--' }}</p>
						</div>
						<div class="size fontColor">
							<span>规格：{{ getOrderRefunds.specification || '--' }}</span>
							<span>x{{ getOrderRefunds.quantity || '--' }}</span>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- 换货原因 -->
		<section class="changeReason">
			<div class="layout">
				<span>{{ isReturn ? '退货原因' : '换货原因' }}</span>
				<span>{{ getOrderRefunds.refundReson || '--' }}</span>
			</div>
			<div v-if="isReturn" class="layout">
				<span>退款金额</span>
				<span>￥ {{ getOrderRefunds.refundAmount || '0.00' }}</span>
			</div>
			<div class="layout">
				<p class="layout-p">补充描述：{{ getOrderRefunds.resonDescribe || '' }}</p>
			</div>
			<div class="resonImg"
				v-if="getOrderRefunds.resonUrlList && getOrderRefunds.resonUrlList.length && getOrderRefunds.resonUrlList.length > 0">
				<van-image v-for='(v, i) in getOrderRefunds.resonUrlList' :key='i' width="80" height="80" :src="v"
					fit="cover" />
			</div>
			<div class="layout">
				<span>申请时间</span>
				<span>{{ getOrderRefunds.createTime || '--' }}</span>
			</div>
			<div class="layout">
				<span>订单编号</span>
				<p>{{ getOrderRefunds.orderId || '--' }}<span class="copy clipboard"
						:dataClipboard='getOrderRefunds.orderId' @click="getCopy('.clipboard')">复制</span></p>
			</div>
			<div></div>
		</section>
		<!-- 客服 -->
		<section class="serve info-btn">
			<div @click="contactShop" class="serveBtn"> <img style="width:0.5189rem" class="btnImg"
					src="../../assets/img/willPay01.png" alt="图片加载失败" /><a class="btnA">商家客服</a></div>
			<div class="serveBtn" @click="callPhone">
				<img class="btnImg" style="width:0.5189rem" src="../../assets/img/willPay02.png" alt="图片加载失败" />
				<a ref="tels" class="btnA">官方客服</a>
			</div>
		</section>
		<!-- 撤销/重新申请 -->
		<section class="footer">
			<!-- <p>——已经到底啦——</p> -->
		</section>
		<section class="payment"
			v-if="activeStatus < 2 || activeStatus1 < 2 || getOrderRefunds.barterType === '9' || getOrderRefunds.barterType === '11'">
			<van-tabbar :fixed="isTop">
				<button @click="applyAgain"
					v-if="getOrderRefunds.barterType === '9' || getOrderRefunds.barterType === '11'"><span>重新申请</span></button>
				<button @click="revocation" v-else-if="activeStatus < 2 || activeStatus1 < 2"><span>撤销申请</span></button>
			</van-tabbar>
		</section>
		<!-- 到底了 -->

	</div>
</template>
<script>
import ClipboardJS from "clipboard"
import { Toast } from "vant";
import {
	getChangeDetail, cancelChange
} from '@/api/changeGoodsDetail';
import { giveUpApply } from '@/api/goodsService/index.js'
// import { getOrderLogistics } from '@/api/orderIdDetail/index.js'
import { contact } from "@/common"
export default {
	data() {
		return {
			isTop: true,
			getOrderRefunds: {},
			orderInfo: { types: '0' }, //商品及订单信息
			closeTime: {
				day: '',
				hours: '',
				minutes: '',
				second: ''
			},
			ShopSales: {},//商家信息
			// logisticsInfo: {},//商家发货物流
			// logisticsCompanyInfo: [],//快递公司
			// company: '',//快递公司
			timer: null, //清除定时器
			timer1: null,
			timeNum: null,
			// ceshi: ''//测试用
			// shijian2: ''//测试ios时间兼容
		}
	},
	created() {
		this.getPageInfo()
	},
	computed: {
		activeStatus() {
			switch (this.getOrderRefunds.barterType) {
				case '1':
					return 0
				case '2':
					return 1
				case '3':
					return 2
				case '4':
					return 3
				case '5':
					return 5
				default:
					return 5
			}
		},
		activeStatus1() {
			switch (this.getOrderRefunds.barterType) {
				case "1":
					return 0;
				case "2":
					return 1;
				case "3":
					return 2;
				case "6":
					return 3;
				case "7":
					return 4;
				default:
					return 4;
			}
		},
		isReturn() {
			switch (this.getOrderRefunds.status) {
				case "2":
					return true;
				case "22":
					return true;
				case "211":
					return true;
				case "212":
					return true;
				case "213":
					return true;
				default:
					return false;
			}
		},
	},
	mounted() {

		//样式适配
		let screenWidth = document.documentElement.getBoundingClientRect().width
		document.documentElement.style.fontSize = (screenWidth / 10) + 'px'
		//刷新页面挂全局
		if (this.$route.query.session) {
			this.$store.commit('setSession', this.$route.query.session)
		}
		window.fillLogisticSuccess = this.fillLogisticSuccess
	},
	activated() {
		this.getPageInfo();
	},
	methods: {
		//复制方法
		getCopy(dom) {
			// console.log(dom, '???');
			// 1. 绑定剪贴板操作方法(订单号)
			let clipboard = new ClipboardJS(dom, {
				text: function (trigger) {
					// 2. trigger 就是点击的 dom 元素，所以就可以通过它来获取到它的属性 'dataClipboard' 的值了
					// 3. 从这个方法返回的 string 就是会复制到剪贴板中的内容，所以可以复制任何内容了，也可以在这里处理一些逻辑
					// 4. 我在这里就只是单纯的输出了事先绑定好的值
					// console.log(trigger.getAttribute('dataClipboard'));
					return trigger.getAttribute("dataClipboard");
				},
			});
			// 5. 当复制成功的时候提示复制成功
			clipboard.on("success", () => {
				// 还可以添加监听事件，如：复制成功后提示
				Toast.success("复制成功");
			});
		},
		//打电话方法：
		callPhone() {
			let u = navigator.userAgent;
			let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
			let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
			if (isIOS) {
				// window.location.href = 'tel:15365526315'
				this.$refs.tels.href = 'tel://15365526315'
				this.$refs.tels.click()
			}
			if (isAndroid) {
				window.android.callPhone('15365526315');
			}
		},
		//跳转到给页面获取数据
		fillLogisticSuccess() {
			this.getPageInfo()
		},
		//获取页面数据
		getPageInfo() {
			// getChangeDetail('1628217805620953091').then(res => {
			getChangeDetail(this.$route.query.id).then(res => {
				if (res.data.code === 0) {
					// this.ceshi = res.data.data
					console.log(res.data.data, '<===信息');
					this.getOrderRefunds = res.data.data.getOrderRefunds
					// this.consentTime = res.data.data.getOrderRefunds.consentTime || ''//待发货时间
					// this.createTime = res.data.data.getOrderRefunds.createTime || ''//提交申请时间
					// this.refuseRefundReson = res.data.data.getOrderRefunds.refuseRefundReson || ''//拒绝换货原因
					this.orderInfo = res.data.data.orderItemDTO || ''//商品信息
					this.ShopSales = res.data.data.ShopSales || ''//商家详细信息
					// this.orderInfo.saleId = res.data.data.getOrderRefunds.id || ''//撤销申请id
					// this.orderInfo.orderId = res.data.data.getOrderRefunds.orderId || ''//撤销申请id
					// this.orderInfo.itemId = res.data.data.getOrderRefunds.orderItemId || ''//撤销申请id
					// this.quantity = res.data.data.getOrderRefunds.quantity || ''//数量
					// this.shopId = res.data.data.getOrderRefunds.shopId//店铺id
					// this.specification = res.data.data.getOrderRefunds.specification || ''//规格
					// this.refundReson = res.data.data.getOrderRefunds.refundReson || ''//原因
					// this.resonDescribe = res.data.data.getOrderRefunds.resonDescribe || ''//原因描述
					this.getOrderRefunds.resonUrlList =
						res.data.data.getOrderRefunds.resonUrl ? res.data.data.getOrderRefunds.resonUrl.split(",") : null;//图片
					// this.orderId = res.data.data.getOrderRefunds.orderId || ''//订单id
					// this.barterType = res.data.data.getOrderRefunds.barterType || ''//换货状态
					if (this.isReturn && this.getOrderRefunds.countDown) {
						this.getRefundTime(this.getOrderRefunds.countDown)
					} else {
						if (this.getOrderRefunds.barterType === '1') {
							this.getRemainTime(res.data.data.getOrderRefunds.createTime)
						} else if (this.getOrderRefunds.barterType === '2') {
							this.getRemainTime(res.data.data.getOrderRefunds.consentTime)
						}
					}
					// Toast(`${this.barterType}`)
					/* this.logisticsInfo.sellerLogisticsNo = res.data.data.getOrderRefunds.sellerLogisticsNo || ''//运单号
					this.logisticsInfo.sellerLogistics = res.data.data.getOrderRefunds.sellerLogistics || ''//快递公司
					getOrderLogistics().then(res1 => {
						this.logisticsCompanyInfo = res1.data.data
						this.company = this.logisticsCompanyInfo.filter(val => {
							return val.value === this.logisticsInfo.sellerLogistics
						})
					}) */
				}
			}).catch((err) => {
				Toast.fail(`数据获取失败，请退出重试${err}`)
			})
			// return '你拿到了吗'

		},
		// 跳转到店铺详情页
		toShopDetail() {
			this.$router.push({
				path: '/flMallStoreDetail',
				query: {
					id: this.getOrderRefunds.shopId,
					type: 'h5'
				}
			})
		},
		//左上角返回到售后页面
		backToServe() {
			let u = navigator.userAgent;
			let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
			let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
			if (isIOS) {
				window.webkit.messageHandlers.flMallOrderRefund.postMessage("");
			}
			if (isAndroid) {
				window.android.flMallOrderRefund();
			}
		},
		getRefundTime(time) {
			this.timeNum = time
			if (this.timeNum > 0) {
				this.timer = setInterval(() => {
					if (this.timeNum > 0) {
						this.timeNum = this.timeNum - 1
						// this.closeTime.day = Math.floor(Number(this.timeNum) / 60 / 60 / 24)
						this.closeTime.hours = Math.floor(Number(this.timeNum) / 60 / 60) >= 10 ? Math.floor(Number(this.timeNum) / 60 / 60) : '0' + Math.floor(Number(this.timeNum) / 60 / 60)
						this.closeTime.minutes = Math.floor(Number(this.timeNum) / 60 % 60) >= 10 ? Math.floor(Number(this.timeNum) / 60 % 60) : '0' + Math.floor(Number(this.timeNum) / 60 % 60)
						this.closeTime.second = Math.floor(Number(this.timeNum) % 60) >= 10 ? Math.floor(Number(this.timeNum) % 60) : '0' + Math.floor(Number(this.timeNum) % 60)
					} else {
						clearInterval(this.timer)
						this.getPageInfo()
					}
				}, 1000)
			}
		},
		//返回订单列表
		/* goToList() {
			let u = navigator.userAgent;
			let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
			let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
			if (isIOS) {
				window.webkit.messageHandlers.flMallOrderRefund.postMessage("");
			}
			if (isAndroid) {
				window.android.flMallOrderRefund();
			}
		}, */
		//联系商家
		contactShop() {
			// console.log(this.shopsGoods[0][0].accid);
			//线下会报错，上线就没事了
			contact(this.orderInfo.accid)
		},
		getRemainTime(time) {
			if (time) {
				this.timer = setInterval(() => {
					if (this.getOrderRefunds.barterType === '1') {
						let createOrder = new Date(time.replace(/-/g, "/")).getTime() + 86399000 - new Date().getTime()
						if (createOrder > 1000) {
							let hours = Math.floor(createOrder / 1000 / 60 / 60 % 24)
							let minutes = Math.floor(createOrder / 1000 / 60 % 60)
							let second = Math.floor(createOrder / 1000 % 60)
							// console.log(hours, minutes, '111');
							this.closeTime.hours = hours >= 10 ? hours : '0' + hours
							this.closeTime.minutes = minutes >= 10 ? minutes : '0' + minutes
							this.closeTime.second = second >= 10 ? second : '0' + second
						} else {
							clearInterval(this.timer)
							this.getPageInfo()
						}
					} else if (this.getOrderRefunds.barterType === '2') {
						let createOrder = new Date(time.replace(/-/g, "/")).getTime() + 259200000 - new Date().getTime()
						if (createOrder > 1000) {
							let day = Math.floor(createOrder / 1000 / 60 / 60 / 24)
							// console.log(day);
							let hours = Math.floor(createOrder / 1000 / 60 / 60 % 24)
							let minutes = Math.floor(createOrder / 1000 / 60 % 60)
							let second = Math.floor(createOrder / 1000 % 60)
							// console.log(hours, minutes, '222');
							this.closeTime.day = day >= 0 ? day : '0'
							this.closeTime.hours = hours >= 10 ? hours : '0' + hours
							this.closeTime.minutes = minutes >= 10 ? minutes : '0' + minutes
							this.closeTime.second = second >= 10 ? second : '0' + second
						} else {
							clearInterval(this.timer)
							this.getPageInfo()
						}
					}
				}, 1000)
			} else {
				this.closeTime.day = "0";
				this.closeTime.hours = "00";
				this.closeTime.minutes = "00";
				this.closeTime.second = "00";
			}
		},
		//撤销申请
		revocation() {
			if (this.isReturn) {//退货退款的
				giveUpApply({ saleId: this.getOrderRefunds.id, types: "1" })
					.then((res) => {
						if (res.data.code == 0) {
							Toast.success("已申请");
							this.timer1 = setTimeout(() => {
								this.backToServe();
							}, 500);
						} else {
							Toast(res.data.message)
						}
					})
					.catch((err) => {
						Toast.fail("撤销失败", err);
					});
			} else {//换货的
				cancelChange({ saleId: this.getOrderRefunds.id, types: '0' }).then(res => {
					if (res.data.code == 0) {
						Toast.success('已申请');
						this.timer1 = setTimeout(() => {
							this.backToServe()
						}, 500);

					} else {
						Toast(res.data.message)
					}
				}).catch(err => {
					Toast.fail('撤销失败', err)
				})
			}
		},
		//重新申请
		applyAgain() {
			console.log('???');
			if (this.isReturn) {
				// console.log(this.getOrderRefunds, '要带的数据');
				this.$router.push({
					path: "/returnMoneyOrGoods",
					query: { code: 1, itemId: this.getOrderRefunds.orderItemId, saleId: this.getOrderRefunds.id, shopType: '0', applyType: 2 },
				});
			} else {
				this.orderInfo.types = '0'
				this.orderInfo.orderId = this.getOrderRefunds.orderId
				this.orderInfo.saleId = this.getOrderRefunds.id
				this.orderInfo.itemId = this.getOrderRefunds.orderItemId
				this.$router.push({
					path: '/applyForChange',
					query: { data: JSON.stringify(this.orderInfo) }
				})
			}
		},
		//点击跳转到填写物流页面
		goWrite() {
			let u = navigator.userAgent;
			let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
			let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
			if (isIOS) {
				window.webkit.messageHandlers.flMallFillLogistics.postMessage(JSON.stringify({
					orderInfo: this.orderInfo,
					specification: this.getOrderRefunds.specification,
					quantity: this.getOrderRefunds.quantity,
					ShopSales: this.ShopSales,
					orderId: this.getOrderRefunds.id
				}));
			}
			if (isAndroid) {
				window.android.flMallFillLogistics(JSON.stringify({
					orderInfo: this.orderInfo,
					specification: this.getOrderRefunds.specification,
					quantity: this.getOrderRefunds.quantity,
					ShopSales: this.ShopSales,
					orderId: this.getOrderRefunds.id
				}));
				/* //强制刷新页面
				window.addEventListener("pageshow", () => {
					this.$router.go(0)
				}, false) */
			}
		}
	},
	beforeDestroy() {
		this.clipboard.destroy()
		clearInterval(this.timer)
		this.timer = null
		clearInterval(this.timer1)
		this.timer1 = null
	},
}
</script>

<style lang="less" scoped>
.body {
	width: 100%;
	height: 100%;
	background: #F6F6F6;

	.fontColor {
		line-height: 0.7784rem;
		color: #666666;
		font-size: 0.3459rem;
	}

	.van-step--vertical .van-step__circle-container {
		z-index: 0;
	}

	section {
		padding: 0.173rem 0.4324rem;
		margin-bottom: 0.4324rem;
		background: #fff;
	}

	.top {
		height: 6%;
		margin-bottom: 0
	}

	.changeProcess {
		font-size: 0.4324rem;

		.createTimeSize {
			font-size: 0.2595rem;
			color: #999999;
			margin-left: 0.4324rem;
		}

		.remainTime {
			font-size: 0.3892rem;
			margin-left: 0.4324rem;

			span {
				font-size: 0.2595rem;
				color: #999999;
				margin-left: 0.4324rem;

			}
		}

		.promptText {
			padding: 0.4324rem 0;
			font-size: 0.3459rem;
			color: #999999;

			.copy {
				color: #E54320;
				margin-left: 0.2595rem;
				text-decoration: underline;
			}
		}
	}

	.shopInfo {
		margin-bottom: 0.4324rem;
		display: flex;
		flex-flow: column nowrap;
		padding: 0.5189rem 0.5189rem;

		.shopName {
			display: flex;
			flex-flow: row nowrap;
			justify-content: flex-start;
			align-items: center;
			padding: 0.4324rem 0;

			span {
				margin: 0 0.4324rem;
				font-size: 0.35rem;
				max-width: 6.4865rem;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}

		.goodsInfo {
			display: flex;
			flex-flow: column nowrap;
			padding: 0.5189rem 0;

			.goodsList {
				display: flex;
				flex-flow: row nowrap;

				.goodsText {
					margin-left: 0.5189rem;
					display: flex;
					flex: 1;
					flex-flow: column nowrap;
					justify-content: space-between;

					p {
						// width: 6.4865rem;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}

					.size {
						display: flex;
						justify-content: space-between;
					}
				}
			}
		}

	}

	.serve {
		width: 100%;
		display: flex;
		justify-content: space-around;
		padding: 0.8649rem 0;

		.serveBtn {
			display: flex;
			justify-content: space-around;
			align-items: center;
			padding: 0 0.173rem;
			width: 2.6811rem;
			height: 0.8649rem;
			border: 1px solid rgb(202, 202, 202);
			background: #fff;
			border-radius: 0.4324rem;

			.btnA {
				color: #000;
				text-decoration: none;
				-webkit-tap-highlight-color: rgba(255, 255, 255, 0);
				// -webkit-user-select: none;
				// -moz-user-focus: none;
				// -moz-user-select: none;
			}

			.btnImg {
				height: 0.5189rem;
				width: 0.5189rem // z-index: 1000;
			}
		}
	}

	.payment {
		// height: 1.8rem;

		.van-tabbar {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			height: 1.7rem;

			button {
				width: 2.5946rem;
				height: 0.8649rem;
				margin-right: 0.6919rem;
				border: 1px solid #999999;
				background: #fff;
				border-radius: 0.4324rem;
			}

			.buy {
				background: #E54320;
				color: #fff;
			}
		}

	}

	.changeReason {

		.layout {
			display: flex;
			justify-content: space-between;
			padding: 0.4324rem 0;

			&-p {
				overflow: hidden;
				text-overflow: ellipsis;
				-webkit-line-clamp: 3;
				display: -webkit-box;
				-webkit-box-orient: vertical;
			}

			.copy {
				color: #E54320;
				text-decoration: underline;
				margin-left: 0.4324rem;
				font-size: 0.3rem;
			}
		}


		.resonImg {
			display: flex;
			flex-flow: row wrap;
			justify-content: flex-start;
			padding: 0.4324rem 0;

			.van-image {
				margin-right: 0.4324rem;
			}
		}
	}

	.footer {
		height: 1.4rem;
		display: flex;
		justify-content: center;
		align-items: flex-end;

		p {
			text-align: center;
			color: #999999;
		}
	}

	.writeLogisticsInfo {
		.main {
			padding-top: 0.4324rem;

			.logisticsInfo {
				display: flex;
				align-items: flex-start;


				.shopAddressInfo {
					flex: 1;

					.copyInfo {
						display: flex;
						justify-content: space-between;

						.copy {
							color: #E54320;
							text-decoration: underline;
						}

						span {
							margin: 0 0.4324rem;
						}
					}

					.address {
						line-height: 0.8649rem;
						margin-left: 0.4324rem;
						min-height: 0.8649rem;
					}
				}

			}
		}

		.logisticsBtn {
			padding: 0.4324rem 0;
			display: flex;
			justify-content: flex-end;
			align-items: center;
		}
	}
}
</style>
